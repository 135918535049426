import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";

import MenuIcon from "../../assets/images/Menu.svg";
import LogoIcon from "../../assets/images/LogoBlack.svg";

import {
  SideNavigationRow, SideNavigationWrapper, SideNavigationHeader, SideNavigationHeaderLeft,
  SideNavigationOverlay, SideNavigationRowState, SideNavigationRowText, StyledLink,
} from "./styled";
import { BasicImgWrapper, BasicText, COLORS } from "../styled";

import { AppServiceContext, AppServiceInstance } from "../../services/app";
import { Routes } from "../../utils/routes";
import { StorageServiceContext } from "../../services/storage";
import { MomentServiceContext } from "../../services/moment";

export const NavigationComponent = observer((): JSX.Element => {
  const { pathname } = useLocation();

  const { navigationOpened } = useContext(AppServiceContext);

  const [initiated, setInitiated] = useState(false);

  const {
    selectedRow, selectedSeat, selectedSection, selectedGameData, selectedMoment,
  } = useContext(StorageServiceContext);
  const { momentList } = useContext(MomentServiceContext);

  const usersPlace = useMemo(() => {
    if (selectedSection && selectedRow && selectedSeat) {
      return `(Sec.${selectedSection} Row.${selectedRow} Seat.${selectedSeat})`;
    }
    return null;
  }, [selectedSection, selectedRow, selectedSeat]);

  const gameData = useMemo(() => {
    if (!selectedGameData || !selectedGameData.team1 || !selectedGameData.team2) {
      return null;
    }

    const { team1, team2 } = selectedGameData;

    const firstTeamName = team1.name.length <= 8 ? team1.name : `${team1.name.substring(0, 8)}.`;
    const secondTeamName = team2.name.length <= 8 ? team2.name : `${team2.name.substring(0, 8)}.`;

    return `(${firstTeamName} vs. ${secondTeamName})`;
  }, [selectedGameData]);

  useEffect(() => {
    if (navigationOpened) {
      setInitiated(true);
    }
  }, [navigationOpened]);

  useEffect(() => {
    setInitiated(false);
  }, [pathname]);

  if (!initiated) {
    return null;
  }

  return (
    <>
      <SideNavigationOverlay
        initiated={initiated}
        opened={navigationOpened}
        onClick={AppServiceInstance.toggleNavigationBar}
      />
      <SideNavigationWrapper opened={navigationOpened} initiated={initiated}>

        <SideNavigationHeader>
          <SideNavigationHeaderLeft>
            <BasicImgWrapper src={MenuIcon} onClick={AppServiceInstance.toggleNavigationBar} />
            <BasicText fontSize="17px" fontWeight="600" color={COLORS.WHITE}>Menu</BasicText>
          </SideNavigationHeaderLeft>
          <BasicImgWrapper src={LogoIcon} />
        </SideNavigationHeader>

        <StyledLink to={Routes.GAMES}>
          <SideNavigationRow>
            <SideNavigationRowText>Select Game</SideNavigationRowText>
            {gameData && <SideNavigationRowState>{gameData}</SideNavigationRowState>}
          </SideNavigationRow>
        </StyledLink>

        <StyledLink to={Routes.SEAT} onClick={(e) => !gameData && e.preventDefault()}>
          <SideNavigationRow>
            <SideNavigationRowText disabled={!gameData}>Select Seat</SideNavigationRowText>
            {usersPlace && <SideNavigationRowState>{usersPlace}</SideNavigationRowState>}
          </SideNavigationRow>
        </StyledLink>

        <StyledLink
          to={Routes.MOMENT}
          onClick={(e) => (!usersPlace || !momentList.length) && e.preventDefault()}
        >
          <SideNavigationRow>
            <SideNavigationRowText disabled={!usersPlace || !momentList.length}>
              Relive The Moments
            </SideNavigationRowText>
          </SideNavigationRow>
        </StyledLink>

        <StyledLink
          to={Routes.MOMENTO}
          onClick={(e) => (!momentList.length || !selectedMoment) && e.preventDefault()}
        >
          <SideNavigationRow>
            <SideNavigationRowText disabled={!momentList.length || !selectedMoment}>
              Explore Your Momento&apos;s
            </SideNavigationRowText>
          </SideNavigationRow>
        </StyledLink>

        <StyledLink to={Routes.WELCOME}>
          <SideNavigationRow>
            <SideNavigationRowText>Back To Main</SideNavigationRowText>
          </SideNavigationRow>
        </StyledLink>
      </SideNavigationWrapper>
    </>
  );
});
