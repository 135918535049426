import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

import { BasicText, COLORS, Z_INDEX } from "../styled";

interface NavigationOpenedProps {
  opened: boolean;
  initiated: boolean;
}

const FadeInAnimation = (maxOpacity: number) => keyframes`
  0% {
    transform:translate(9999px);
    opacity: 0;
  }
  1% {
    transform:translate(0);
    opacity: 0;
  }
  100% {
    transform:translate(0);
    opacity: ${maxOpacity};
  }
`;

const FadeOutAnimation = (maxOpacity: number) => keyframes`
  0% {
    transform:translate(0);
    opacity: ${maxOpacity};
  }
  99% {
    transform:translate(0);
    opacity: 0;
  }
  100% {
    transform:translate(9999px);
    opacity: 0;
  }
`;

export const SideNavigationOverlay = styled.div<NavigationOpenedProps>`
  width: 100%;
  height: 100%;
  background: #000000;
  mix-blend-mode: normal;
  position: fixed;
  z-index: ${Z_INDEX.HIGHEST - 1};
  opacity: 0;
  display: fixed;
  transform: translate(${({ opened }) => (opened ? "0" : "9999px")});
  opacity: ${({ opened }) => (opened ? "0.8" : "0")};
  animation: ${({ opened }) => (opened ? FadeInAnimation(0.8) : FadeOutAnimation(0.8))};
  animation-duration: 0.5s;
`;

export const SideNavigationWrapper = styled.div<NavigationOpenedProps>`
  flex-direction: column; 
  justify-content: flex-start;
  height: 100%;
  width: 80%;
  background-color: ${COLORS.BLUE_MAIN};
  padding: 0 16px;
  z-index: ${Z_INDEX.HIGHEST};
  position: fixed;
  transform: translate(${({ opened }) => (opened ? "0" : "9999px")});
  opacity: ${({ opened }) => (opened ? "1" : "0")};
  animation: ${({ opened, initiated }) => (!initiated ? "none" : opened ? FadeInAnimation(1) : FadeOutAnimation(1))};
  animation-duration: 0.7s;
`;

export const SideNavigationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 0 5px 0;
`;

export const SideNavigationHeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 6px;

  & > * {
    padding-right: 10px;
  }
`;

export const SideNavigationRow = styled.div`
  height: 65px;
  border-bottom: 1px solid ${COLORS.BLUE_LIGHT};
  padding: 32px 0 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface SideNavigationRowTextProps {
  disabled?: boolean;
}

export const SideNavigationRowText = styled(BasicText)<SideNavigationRowTextProps>`
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.41px;
  color: ${COLORS.WHITE};
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};
`;

export const SideNavigationRowState = styled(SideNavigationRowText)`
  color: ${COLORS.GREEN};
  font-size: 15px;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;
