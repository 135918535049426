import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import { GetGameMomentsRequests, BasicResponse, MomentResponseWithSize } from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

interface FetchGameMomemntResponse {
  data: MomentResponseWithSize;
  success: boolean;
  error?: string;
}

export const checkGameMomentRequest = async (
  request: GetGameMomentsRequests,
): Promise<BasicResponse> => {
  try {
    const response = await instance.post<BasicResponse>(`${PATH.API}/${PATH.MOMENT}/${PATH.CHECK}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const fetchGameMomentsRequest = async (
  request: GetGameMomentsRequests,
): Promise<FetchGameMomemntResponse> => {
  try {
    const response = await instance.post<MomentResponseWithSize>(`${PATH.API}/${PATH.MOMENT}`, request);

    return {
      data: response.data,
      success: true,
    };
  } catch (err) {
    const { response } = err as IAxiosError;
    if (response.status === 404) {
      return {
        data: null,
        success: false,
        error: `No moments found for section ${request.mappedSeat.sectionName} (row: ${request.mappedSeat.row}, seat: ${request.mappedSeat.seat})`,
      };
    }
    throw new Error(getError(response));
  }
};
