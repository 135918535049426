import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import { IntervalGamePopulated } from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

export const getGamesByDateRequest = async (
  date: number,
): Promise<IntervalGamePopulated[]> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.GAME}?start=${date}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getGameDataByIdRequest = async (gameId: string): Promise<IntervalGamePopulated> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.GAME}/${gameId}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
