export enum PATH {
  API = "api",
  API_DOCS = "api-docs",
  FILE = "file",
  SPORT = "sport",
  LEAGUE = "league",
  TEAM = "team",
  OPERATOR = "operator",
  STATE = "state",
  GAME = "game",
  AUTH = "auth",
  STADIUM = "stadium",
  CITY = "city",
  POPULATED = "populated",
  CAMERA = "camera",
  LIST = "list",
  ITEM = "item",
  PREVIEW = "preview",
  MOMENT = "moment",
  CHECK = "check",
  STATUS = "status",
  NOTIFY = "notify",
  INFO = "info",
  LINK = "link",
  ALL = "all",
  GENERAL = "general",
}
