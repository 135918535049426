export enum Routes {
  WELCOME="/",
  GAMES="/games",
  SEAT="/seat",
  MOMENT="/moment",
  ALL_MOMENTO="/all_momento",
  MOMENTO="/momento",
  EDITOR="/editor",
}

export enum Params {
  ID="id",
  INDEX="index"
}
