import { observable, makeAutoObservable, action, runInAction } from "mobx";
import { createContext } from "react";
import axios from "axios";
import { message } from "antd";

import { showErrorNotification } from "../utils/notification";
import { IntervalGamePopulated } from "../shared/interfaces";

import { AppServiceInstance } from "./app";
import { getGameDataByIdRequest } from "../axios/routes/game";
import { Routes } from "../utils/routes";
import { StorageCropData, ImageCropData } from "../interfaces/moment";

enum SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM {
  GAME="/selectedGame",
  SECTION="/selectedSection",
  ROW="/selectedRow",
  SEAT="/selectedSeat",
  MOMENT="/selectedMoment",
  CROP_DATA="/imageCropData",
}

class StorageService {
  @observable selectedGame: string = null;

  @observable selectedGameData: IntervalGamePopulated = null;

  @observable selectedSection: string = null;

  @observable selectedRow: number = null;

  @observable selectedSeat: number = null;

  @observable storageCropData: StorageCropData = null;

  // TODO: change to actual time
  @observable selectedMoment: string = null;

  constructor() {
    makeAutoObservable(this);

    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.GAME);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.ROW);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SEAT);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.MOMENT);
    this.fetchDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.CROP_DATA);
  }

  @action selectGame = (game: IntervalGamePopulated) => {
    this.selectedGame = game._id;

    AppServiceInstance.history.push(Routes.SEAT);

    this.fetchGameData();

    this.saveDataToWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.GAME, this.selectedGame);
  }

  @action public saveUsersPlace = (section: string, row: number, seat: number) => {
    this.selectedSection = section;
    this.selectedRow = row;
    this.selectedSeat = seat;

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION, this.selectedSection,
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.ROW, this.selectedRow.toString(),
    );
    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SEAT, this.selectedSeat.toString(),
    );
  }

  // TODO: change to actual type
  @action saveMoment = (momentId: string) => {
    this.selectedMoment = momentId;

    this.saveDataToWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.MOMENT, momentId);
  }

  @action deleteCurrentMoment = () => {
    this.selectedMoment = null;
    this.deleteDataFromWorkerStorage(SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.MOMENT);
  }

  @action public saveImageCropData = (
    cropData: ImageCropData,
    momentId?: string,
    momentoIndex?: number,
  ) => {
    if (!this.canManipulateCropData) return;

    const key = this.generateCropDataKey(momentId, momentoIndex);

    this.storageCropData = {
      ...this.storageCropData,
      [key]: cropData,
    };

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.CROP_DATA,
      JSON.stringify(this.storageCropData),
    );
  }

  public getImageCropData = (momentId: string, momentoIndex: number): ImageCropData|null => {
    if (!this.canManipulateCropData || !this.storageCropData) return null;

    const momentoKey = this.generateCropDataKey(momentId, momentoIndex);
    if (this.storageCropData[momentoKey]) {
      return this.storageCropData[momentoKey];
    }

    const momentKey = this.generateCropDataKey(momentId);
    if (this.storageCropData[momentKey]) {
      return this.storageCropData[momentKey];
    }

    const globalKey = this.generateCropDataKey();
    return this.storageCropData[globalKey] || null;
  }

  public removeImageCropData = (
    momentId?: string,
    momentoIndex?: number,
  ): void => {
    const matchKey = this.generateCropDataKey(momentId, momentoIndex);

    if (!this.storageCropData || Object.keys(this.storageCropData).length === 0) {
      message.info("There's nothing to remove");
      return;
    }

    for (const key of Object.keys(this.storageCropData)) {
      if (key.match(matchKey)) {
        delete this.storageCropData[key];
      }
    }

    this.saveDataToWorkerStorage(
      SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.CROP_DATA,
      JSON.stringify(this.storageCropData),
    );
  }

  public generateCropDataKey = (momentId?: string, momentoIndex? : number): string => {
    const basicKey = `${this.selectedGame}_${this.selectedSection}_${this.selectedRow}_${this.selectedSeat}`;
    let resultKey = basicKey;
    if (momentId) {
      resultKey = `${resultKey}_${momentId}`;
    }
    if (momentoIndex || momentoIndex === 0) {
      resultKey = `${resultKey}_${momentoIndex}`;
    }
    return resultKey;
  }

  private canManipulateCropData = () => {
    let valid = true;
    if (!this.selectedGame) {
      showErrorNotification("No game selected");
      valid = false;
    }
    if (!this.selectedSeat) {
      showErrorNotification("No seat selected");
      valid = false;
    }
    return valid;
  }

  private fetchDataFromWorkerStorage = async (entity: SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM) => {
    try {
      const { data } = await axios.get<string>(entity);

      if (data) {
        runInAction(() => {
          switch (entity) {
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.GAME:
              console.log(`> Found stored game ${data}`);
              this.selectedGame = data;
              this.fetchGameData();
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SECTION:
              console.log(`> Found stored section ${data}`);
              this.selectedSection = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.ROW:
              console.log(`> Found stored row ${data}`);
              this.selectedRow = Number(data);
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.SEAT:
              console.log(`> Found stored seat ${data}`);
              this.selectedSeat = Number(data);
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.MOMENT:
              console.log(`> Found stored moment ${data}`);
              this.selectedMoment = data;
              break;
            case SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM.CROP_DATA:
              console.log(`> Found stored crop data ${data}`);
              this.storageCropData = JSON.parse(data);
              break;
            default:
              break;
          }
        });
      } else {
        console.log(`> No data was found in the storage for entity ${entity}`);
      }
    } catch (err) {
      console.log("Something went wrong while searching for the data in the worker's storage");
    }
  }

  private fetchGameData = async () => {
    try {
      this.selectedGameData = await getGameDataByIdRequest(this.selectedGame);
    } catch (err) {
      showErrorNotification(`[fetchGameData] ${err.message}`);
    }
  }

  private saveDataToWorkerStorage = async (
    entity: SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM,
    data: string,
  ) => {
    try {
      await axios.post(entity, { data });
    } catch (err) {
      console.log("^^^ The error above is expected xhr error");
    }
  }

  private deleteDataFromWorkerStorage = async (entity: SERVICE_WORKER_STORAGE_ENDPOINTS_ENUM) => {
    try {
      await axios.delete(entity);
    } catch (err) {
      console.log("^^^ The error above is expected xhr error");
    }
  }
}

export const StorageServiceInstance = new StorageService();

export const StorageServiceContext = createContext(StorageServiceInstance);
