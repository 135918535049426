export enum ASPECT_RATIO_LIST {
  "9_16" = 9 / 16,
  "3_4" = 3 / 4,
  "4_3" = 4 / 3,
  "16_9" = 16 / 9,
  "1_1" = 1,
}

export interface ImageCoordinates {
  x: number;
  y: number;
}

export interface ImageSize {
  width: number;
  height: number;
}

export interface ImageCropData extends ImageCoordinates, ImageSize {
  aspect?: ASPECT_RATIO_LIST;
}

export interface CanvasPositionData extends ImageCoordinates, ImageSize {
  canvasYOffset: number;
  canvasXOffset: number;
}

export interface StorageCropData {
  [gameId: string]: ImageCropData;
}

export type EditorSaveType = "global"|"moment"|"momento";

export type EditorDeleteType = EditorSaveType;

export interface DownloadData extends ImageSize, ImageCoordinates {
  link: string;
  name: string;
}
