import { message as AntdMessage } from "antd";
import { action, makeAutoObservable, observable, reaction, runInAction } from "mobx";
import { createContext } from "react";
import { checkGameMomentRequest, fetchGameMomentsRequest } from "../axios/routes/moment";
import {
  ASPECT_RATIO_LIST, CanvasPositionData, DownloadData,
  EditorSaveType, ImageCoordinates, ImageCropData, ImageSize,
} from "../interfaces/moment";
import { Coords, GetGameMomentsRequests, MomentResponse } from "../shared/interfaces";
import { getGameStartDate } from "../utils/date";
import { showErrorNotification } from "../utils/notification";
import { StorageServiceInstance } from "./storage";

class MomentService {
  @observable momentList: Array<MomentResponse> = [];

  @observable selectedMoment: MomentResponse = null;

  @observable loading = false;

  @observable lastMomentRequest: GetGameMomentsRequests = null;

  private defaultZoomWidth: number = null;

  private defaultZoomHeight: number = null;

  private centerCoords: Coords = null;

  private offsetY: number = null;

  @observable editorAspect: ASPECT_RATIO_LIST = ASPECT_RATIO_LIST["3_4"];

  @observable selectedMomentoSlide: number = null;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => StorageServiceInstance.selectedMoment,
      (id) => {
        this.selectedMoment = this.momentList.find((m) => m._id === id);
      },
    );
  }

  private generateMomentRequest = (
    section?: string, row?: number, seat?: number,
  ): GetGameMomentsRequests => {
    return {
      mappedSeat: {
        sectionName: section || StorageServiceInstance.selectedSection,
        row: row || StorageServiceInstance.selectedRow,
        seat: seat || StorageServiceInstance.selectedSeat,
      },
      from: StorageServiceInstance.selectedGameData.startDate,
      to: StorageServiceInstance.selectedGameData.finishDate || null,
      stadium: StorageServiceInstance.selectedGameData.stadiumId,
    };
  }

  public checkMoments = async (section: string, row: number, seat: number): Promise<boolean> => {
    const request = this.generateMomentRequest(section, row, seat);

    const { message, success } = await checkGameMomentRequest(request);

    if (message) {
      AntdMessage.warn(message);
    }

    return success;
  }

  public fetchMomentList = async () => {
    if (this.loading) return;

    if (!this.dependenciesLoaded()) {
      this.schedule(this.fetchMomentList);
      return;
    }

    this.momentList = [];
    this.loading = true;
    try {
      const request: GetGameMomentsRequests = this.generateMomentRequest();

      const { data, error } = await fetchGameMomentsRequest(request);

      if (error) {
        this.momentList = [];
        AntdMessage.warn(error);
      } else {
        runInAction(() => {
          this.lastMomentRequest = request;
          this.momentList = data.moments;

          this.defaultZoomWidth = data.defaultZoomWidth;
          this.defaultZoomHeight = data.defaultZoomHeight;
          this.centerCoords = data.relativeCoords;
          this.offsetY = data.offsetTop;

          if (StorageServiceInstance.selectedMoment) {
            this.selectedMoment = this.momentList.find(
              (m) => m._id === StorageServiceInstance.selectedMoment,
            );
            this.setAspectRatioWithStore();
          }
        });
      }
    } catch (err) {
      showErrorNotification(`[fetchMomentList] ${err.message}`);
    } finally {
      this.loading = false;
    }
  }

  @action public selectMomentoSlide = (slide: number) => {
    this.selectedMomentoSlide = slide;
    this.setAspectRatioWithStore();
  }

  /**
   * Sets editor's aspect ratio to the saved aspect ratio
   * it will search saved aspect as following for: current momento -> current moment -> global
   * If nothing found in store sets default aspect ratio
   * @param slide number of slide or 0 if none selected
   */
  @action setAspectRatioWithStore = () => {
    const savedCropData = StorageServiceInstance.getImageCropData(
      this.selectedMoment._id,
      this.selectedMomentoSlide || 0,
    );
    const newAspect = savedCropData?.aspect || ASPECT_RATIO_LIST["3_4"];
    console.log(`Set editor's aspect ratio to ${newAspect}`);
    this.editorAspect = newAspect;
  }

  @action public changeAspect = (aspect: ASPECT_RATIO_LIST): void => {
    this.editorAspect = aspect;
  }

  private calculateImageStartPosition = (cropData: ImageCropData|null): ImageCoordinates => {
    if (cropData) {
      return {
        x: cropData.x,
        y: cropData.y,
      };
    }

    return {
      x: this.centerCoords.x - this.defaultZoomWidth / 2,
      y: this.centerCoords.y - this.defaultZoomHeight / 2 - this.offsetY,
    };
  }

  private calculateImageSize = (cropData: ImageCropData|null): ImageSize => {
    if (cropData) {
      return {
        width: cropData.width,
        height: cropData.height,
      };
    }

    return {
      width: this.defaultZoomWidth,
      height: this.defaultZoomHeight,
    };
  }

  /**
   * Returns the position and size of the image to be displayed on canvas
   * Calculate offset of the image on the canvas
   * depending on the aspect ratio of canvas and cropped image
   * @param canvasWidth width of the canvas element
   * @param canvasHeight height of the canvas element
   * @param momentId id of the moment we are searching crop data for
   * @param momentoIndex index of the momentO we are searching crop data for
   */
  public getCanvasImagePosition = (
    canvasWidth: number,
    canvasHeight: number,
    momentId: string,
    momentoIndex: number,
  ): CanvasPositionData => {
    const canvasAspect = canvasWidth / canvasHeight;

    const savedCropData = StorageServiceInstance.getImageCropData(momentId, momentoIndex);

    const imageAspect = savedCropData?.aspect || ASPECT_RATIO_LIST["3_4"];

    const {
      x: startXPosition,
      y: startYPosition,
    } = this.calculateImageStartPosition(savedCropData);

    const { width: imageWidth, height: imageHeight } = this.calculateImageSize(savedCropData);

    // canvas is a square preview, need to cut the bigger side to make image square
    if (canvasAspect === 1) {
      return this.getCanvasSquareImagePosition({
        x: startXPosition,
        y: startYPosition,
        width: imageWidth,
        height: imageHeight,
      });
    }

    // the height of the canvas is excesive, need to add blank spaces at the top and bottom
    if (canvasAspect > imageAspect) {
      // expected width with this aspect ratio and height
      const expectedWidth = canvasHeight * imageAspect;
      const extraCanvasWidth = canvasWidth - expectedWidth;
      const canvasXOffset = extraCanvasWidth / 2;

      return {
        x: startXPosition,
        y: startYPosition,
        width: imageWidth,
        height: imageHeight,
        canvasYOffset: 0,
        canvasXOffset,
      };
    }

    // the width of the canvas is excesive, need to add blank spaces to left and right
    if (canvasAspect < imageAspect) {
      // expected height with this aspect ratio and width
      const expectedHeight = canvasWidth / imageAspect;
      const extraCanvasHeight = canvasHeight - expectedHeight;
      const canvasYOffset = extraCanvasHeight / 2;

      return {
        x: startXPosition,
        y: startYPosition,
        width: imageWidth,
        height: imageHeight,
        canvasYOffset,
        canvasXOffset: 0,
      };
    }

    // canvas and image aspect ratio is perfect match
    return {
      x: startXPosition,
      y: startYPosition,
      width: imageWidth,
      height: imageHeight,
      canvasYOffset: 0,
      canvasXOffset: 0,
    };
  };

  /**
   * Special case of `getCanvasImagePosition` when canvas is a square
   * Method cuts off the larger side of the image to make it square
   * @param imageData image size and position
   */
  private getCanvasSquareImagePosition = (imageData: ImageCropData): CanvasPositionData => {
    // image height is too big, need to cut chunks top and bottom
    if (imageData.height > imageData.width) {
      const extraHeight = imageData.height - imageData.width;
      return {
        x: imageData.x,
        y: imageData.y + extraHeight / 2,
        width: imageData.width,
        height: imageData.height - extraHeight,
        canvasXOffset: 0,
        canvasYOffset: 0,
      };
    }
    // image  width is too big, need to cut chunks from left and right
    if (imageData.height < imageData.width) {
      const extraWidth = imageData.width - imageData.height;
      return {
        x: imageData.x + extraWidth / 2,
        y: imageData.y,
        width: imageData.width - extraWidth,
        height: imageData.height,
        canvasXOffset: 0,
        canvasYOffset: 0,
      };
    }
    // cropped image is already a square
    return {
      ...imageData,
      canvasXOffset: 0,
      canvasYOffset: 0,
    };
  }

  /**
   * Fetches the specific coordinates and sizes of the image
   * Is used while displaying in editor and downloading images
   * @param momentId if provided, will search crop data for this moment,
   * otherwise for currently selected moment
   * @param momentoSlide if provided, will search crop data for this momento,
   * otherwise for momento with index 0
   */
  public getEditorImagePosition = (momentId?: string, momentoSlide?: number): ImageCropData => {
    const savedCropData = StorageServiceInstance.getImageCropData(
      this.getMomentId(momentId),
      this.getMomentoIndex(momentoSlide),
    );

    const { x, y } = this.calculateImageStartPosition(savedCropData);

    const { width, height } = this.calculateImageSize(savedCropData);

    return { x, y, width, height };
  };

  /**
   * Saves crop data inside the worker storage depending on the provided type
   * @param cropData position, size and aspect ratio data
   * @param type type of saving that we perform
   * `momento` - saves crop data only for currently selected momentO
   * `moment` - saves crop data for currently selected moment
   * `global` - saves crop data as global for all moments and momento of current game and seat
   */
  public saveEditorChanges = (cropData: ImageCropData, type: EditorSaveType): void => {
    switch (type) {
      case "global":
        StorageServiceInstance.saveImageCropData(cropData);
        break;
      case "moment":
        StorageServiceInstance.saveImageCropData(cropData, this.selectedMoment._id);
        break;
      case "momento":
        StorageServiceInstance.saveImageCropData(
          cropData,
          this.selectedMoment._id,
          this.selectedMomentoSlide || 0,
        );
        break;
      default:
        showErrorNotification("Wrong saving options");
    }
  }

  /**
   * Deletes crop data inside the worker storage depending on the provided type
   * @param type type of deletion that we perform
   * `momento` - deletes crop data only for currently selected momentO
   * `moment` - deletes crop data for currently selected moment,
   *    changes with `momento` type won't be deleted
   * `global` - deletes all global crop data, crop data for `momento` or `moment` won't be deleted
   */
  public deleteEditorChanges = (type: EditorSaveType): void => {
    switch (type) {
      case "global":
        StorageServiceInstance.removeImageCropData();
        break;
      case "moment":
        StorageServiceInstance.removeImageCropData(this.selectedMoment._id);
        break;
      case "momento":
        if (!this.selectedMomentoSlide && this.selectedMomentoSlide !== 0) {
          AntdMessage.error("No momento selected. Try to get back and select a new one");
          return;
        }
        StorageServiceInstance.removeImageCropData(
          this.selectedMoment._id,
          this.selectedMomentoSlide,
        );
        break;
      default:
        showErrorNotification("Wrong delete options");
    }
  }

  /**
   * Returns all data needed for image downloading including cropped image position,
   * link to src image and name for the file
   * @param wholeMoment `true` if we need to load all momento or gif, otherwise `false`
   * @param gif `true` is we need to load gif, otherwise `false`
   */
  public getDownloadDataArray = (wholeMoment?: boolean, gif?: boolean): DownloadData[] => {
    const targetMomentIndex = this.momentList.findIndex((m) => m._id === this.selectedMoment._id);

    const targetMoment = this.momentList[targetMomentIndex];

    if (!targetMoment) {
      AntdMessage.error("Something went wrong while searching for moment");
      throw new Error();
    }

    if (!wholeMoment) {
      const position = this.getEditorImagePosition();
      const momentoIndex = this.getMomentoIndex();

      return [{
        ...position,
        link: targetMoment.momentoLinks[momentoIndex],
        name: this.generateDownloadName(targetMomentIndex, momentoIndex),
      }];
    }

    return targetMoment.momentoLinks.map((link, index) => {
      const { height, width, x, y } = this.getEditorImagePosition(targetMoment._id, index);
      return <DownloadData>{
        height,
        width,
        x,
        y,
        link,
        name: this.generateDownloadName(targetMomentIndex, gif ? null : index),
      };
    });
  }

  /**
   * Generating file name that will be assigned to
   * the file during donwload
   * Sample name: "Lexington_Legends_vs_New_Team_06_15_2021_m0_0"
   * @param momentIndex index of the moment in moment list received from the server
   * @param momentoIndex index of the momento in the selected moment
   * or null if it shouldn't be specified in name, e.g. when gif is being donwloaded
   */
  private generateDownloadName = (momentIndex: number, momentoIndex: number = null): string => {
    const team1Name = StorageServiceInstance.selectedGameData.team1.name.replace(" ", "_");
    const team2Name = StorageServiceInstance.selectedGameData.team2.name.replace(" ", "_");

    const gameDate = getGameStartDate(StorageServiceInstance.selectedGameData.startDate);

    const baseName = `${team1Name}_vs_${team2Name}_${gameDate}_m${momentIndex}`;

    return momentoIndex !== null ? `${baseName}_${momentoIndex}` : baseName;
  }

  private getMomentId = (momentId?: string): string => {
    return momentId || this.selectedMoment._id;
  }

  private getMomentoIndex = (momentoIndex?: number): number => {
    return momentoIndex || this.selectedMomentoSlide || 0;
  }

  private dependenciesLoaded = (): boolean => {
    return !!StorageServiceInstance.selectedGameData && !!StorageServiceInstance.selectedSeat;
  }

  private schedule = (func: () => void, time = 300): NodeJS.Timeout => {
    return setTimeout(() => {
      func();
    }, time);
  }
}

export const MomentServiceInstance = new MomentService();

export const MomentServiceContext = createContext(MomentServiceInstance);
