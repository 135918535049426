import { observable, makeAutoObservable, reaction, action } from "mobx";
import { createContext } from "react";

import { getGamesByDateRequest } from "../axios/routes/game";
import { IntervalGamePopulated } from "../shared/interfaces";
import { MAX_GAME_DURATION } from "../utils/constants";
import { showErrorNotification } from "../utils/notification";

export type GameType = "all"|"live"|"finished"|"future";

class GameService {
  private games: IntervalGamePopulated[] = [];

  @observable pastGames: IntervalGamePopulated[] = [];

  @observable futureGames: IntervalGamePopulated[] = [];

  @observable liveGames: IntervalGamePopulated[] = [];

  @observable selectedDate: Date = null;

  constructor() {
    makeAutoObservable(this);

    console.log("> Fetching games for today");
    this.fetchGames(new Date());

    reaction(() => this.games, () => this.sortGames());
  }

  fetchGames = async (date: Date) => {
    if (this.selectedDate && this.selectedDate.getTime() === date.getTime()) {
      console.log("> The games for this day are already fetched");
      return;
    }

    this.selectedDate = date;
    try {
      this.games = await getGamesByDateRequest(date.setHours(0, 0, 0, 0));
      this.sortGames();
    } catch (err) {
      showErrorNotification(err.message);
    }
  }

  @action sortGames = () => {
    this.pastGames = [];
    this.futureGames = [];
    this.liveGames = [];

    const currentTime = new Date().getTime();

    this.games.forEach((game) => {
      if (game.finishDate || game.startDate < currentTime - MAX_GAME_DURATION) {
        this.pastGames.push(game);
      } else if (game.startDate < currentTime) {
        this.liveGames.push(game);
      } else {
        this.futureGames.push(game);
      }
    });
  }
}

export const GameServiceInstance = new GameService();

export const GameServiceContext = createContext(GameServiceInstance);
